import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/render/project/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "external-components"
    }}>{`External Components`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Note, this functionality is currently experimental. Please help us flesh it out and make it better!`}</p>
    </blockquote>
    <p>{`Sometimes your product contains functionality or pages that just won't fit in with the platform paradigm. But we still want to provide a unified interface for our users. That's where external components comes in. You can mount a page of your app into the platform using an iframe or if you give us a js component we'll run that for you too.`}</p>
    <p>{`You'll have to be in a ProductBuilder-enabled tenant to get this to work. Go create a new product and then in the left side bar click "+ New Menu Item" and select either Iframe or JS Component. With iframes, we'll mount your app in the main area on the right. With JS Components, we expect you to hand us an ES Module with 2 exports: `}<inlineCode parentName="p">{`start/1`}</inlineCode>{` and `}<inlineCode parentName="p">{`stop/1`}</inlineCode>{`. We'll call `}<inlineCode parentName="p">{`start/1`}</inlineCode>{` anytime your app is activated and `}<inlineCode parentName="p">{`stop/1`}</inlineCode>{` when the user navigates to a different menu item. You'll be passed a reference to the DOM node where you should mount your app as the first and only argument in both of those functions.`}</p>
    <h2 {...{
      "id": "url-substitutions"
    }}>{`URL Substitutions`}</h2>
    <p>{`Your URL can contain any of the following substitutions:`}</p>
    <ul>
      <li parentName="ul">{`{{SUBDOMAIN}} = the subdomain of the platform instance being used`}</li>
      <li parentName="ul">{`{{HOST}} = the host of the platform instance being used`}</li>
    </ul>
    <h2 {...{
      "id": "security-csp"
    }}>{`Security (CSP)`}</h2>
    <p>{`We haven't decided what sources we'll allow into the platform quite yet. We are currently `}<strong parentName="p">{`NOT`}</strong>{` sending a CSP in development or in the Build PRs enviromnent. So you can test out your components on `}<inlineCode parentName="p">{`local.kualibuild.com`}</inlineCode>{` and will be able to share them with others through `}<inlineCode parentName="p">{`buildprs.kualibuild.com`}</inlineCode>{`. The CSP in production is pretty strict so odds are you won't be able to run your component there quite yet. The components you mount in a non-local environment will need to be hosted on an https domain; I'd suggest using netlify as it's super easy to use and free!`}</p>
    <p>{`We're thinking we'll let in anything from `}<inlineCode parentName="p">{`*.kuali.co`}</inlineCode>{`, `}<inlineCode parentName="p">{`*.kualibuild.com`}</inlineCode>{`, `}<inlineCode parentName="p">{`*.kuali.cloud`}</inlineCode>{`, and maybe some other site dedicated for the purpose like `}<inlineCode parentName="p">{`*.kualiplatformcontent.com`}</inlineCode>{` or something. If you're an iframe, it'll be easiest if you host your module under the same domain platform is running on. That way you'll get the authed user's cookie and can make requests against platform apis. Better support for other hosts will come once we've added OAuth support to identity.`}</p>
    <h2 {...{
      "id": "examples"
    }}>{`Examples`}</h2>
    <h3 {...{
      "id": "starter"
    }}>{`Starter`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// this example needs no bundling. Save it to a file, host it, and then reference it

export function start (element) {
  console.log('mounting my app')
  element.style = 'background:lightblue;font-size:72px'
  element.innerText = 'Hello Product Builder!'
}

export function stop (_element) {
  console.log('unmounting my app')
}
`}</code></pre>
    <h3 {...{
      "id": "bundled-react-app"
    }}>{`Bundled React App`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// compile this with:
// esbuild --bundle example.jsx --outdir=dist --format=esm
import React from 'react'
import ReactDOM from 'react-dom'

function App () {
  const [count, setCount] = React.useState(0)
  return (
    <div className='flex h-full flex-col items-center justify-center'>
      <h1>React Counter Example</h1>
      <div className='flex items-center'>
        <button
          className='kp-button-solid'
          onClick={() => setCount(count => count - 1)}
        >
          -
        </button>
        <div className='px-2 text-3xl'>{count}</div>
        <button
          className='kp-button-solid'
          onClick={() => setCount(count => count + 1)}
        >
          +
        </button>
      </div>
    </div>
  )
}

export function start (el) {
  ReactDOM.render(<App />, el)
}

export function stop (el) {
  ReactDOM.unmountComponentAtNode(el)
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      